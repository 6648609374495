import React, { useCallback, useContext, useEffect } from "react";
import { IScreen } from "../components/vs-code/types";
import { FileSystem, Screen } from "../components/vs-code";
import { Auth } from "./ridersUserContext";
import { collectAchievement, getAchievements, getScore } from "../api";
import { createEncryptor } from "simple-encryptor";
import moment from "moment";
import { message as AntMessage } from "antd";

function debounce(func: any, wait: number | undefined) {
  let timeout: string | number | NodeJS.Timeout | undefined;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const encryptor = createEncryptor("simpleKeyForSimpleEncryptor");

export const CommunicationContext = React.createContext({});
export const Communication: IScreen = ({ children }) => {
  const {
    readFile,
    readDir,
    writeFile,
    deleteFile,
    getBasePath,
    getFullPath,
    git_push,
    git_commit,
    git_add,
    gitSyncHandler,
    lastCommitHash,
    setLastCommitHash,
    git_get_last_commit_hash,
    git_status,
  }: any = useContext<any>(FileSystem.context);

  const { godotContainerRef, documentationRef, project }: any = useContext<any>(
    Screen.context
  );
  const { getToken, userCodeFilePath }: any = useContext<any>(Auth.context);

  const encryptMessage = useCallback((message: any) => {
    if (typeof message === "string") {
      return encryptor.encrypt(message);
    } else if (typeof message === "object") {
      return encryptor.encrypt(JSON.stringify(message));
    }
    return "ERROR";
  }, []);

  const postMessage = useCallback((ref: any, msg: any) => {
    const message = JSON.stringify(msg);
    ref.current?.contentWindow.postMessage(message, "*");
  }, []);

  const parentPostMessage = useCallback((msg: any) => {
    const message = JSON.stringify(msg);
    window.parent.postMessage(message, "*");
  }, []);

  interface ActionMap {
    [key: string]: (...args: any[]) => void;
  }

  const actionMap: ActionMap = {
    listFiles: async function () {
      const files = await readDir("/tmp");

      postMessage(godotContainerRef, {
        action: "init",
        key: getBasePath(),
        data: files,
      });
    },

    fsList: async function (path: string, _?: string) {
      const files = await readDir(path);

      postMessage(godotContainerRef, {
        action: "fsListResponse",
        key: getBasePath(),
        data: files,
        status: "success",
        filePath: path,
      });
    },

    init: async function () {
      this.listFiles();
    },

    achieve: async function (message: any, token: any) {
      await collectAchievement(token, encryptMessage(message.data.toString()));
      postMessage(documentationRef, {
        action: "achieve",
        data: message.data,
      });
    },
    score_results: async function (message: any, token: any) {
      await getScore(token, encryptMessage(message.data));
    },
    getachievements: async function (message: any, token: any) {
      // TODO (Etkin): Documentation's ready function is this for now. Fix it.
      const response = { action: "initachievements", data: [] };
      const data = await getAchievements(token);

      if (data.message.includes("Success")) response.data = data.data;
      else console.error({ action: message.action, message: data.message });

      postMessage(documentationRef, response);
    },
    setProgression: async function (message: any, token: any) {
      // AntMessage.success(
      //   JSON.stringify({
      //     action: "progression",
      //     progress: message.progress,
      //   }),
      //   2
      // );
      if (project.isCornellProject) {
        await getScore(
          token,
          encryptMessage({
            score: message.progress,
          })
        );
      }
      if (message.progress === 100) {
        parentPostMessage({
          action: "notify_progression",
          progress: 100,
        });
        // AntMessage.success(
        //   "Congratulations! You have completed the scenario.",
        //   2
        // );
      }
    },
    gitSyncPing: async function () {
      try {
        const code = await readFile(userCodeFilePath);
        console.log("code", moment().format("HH:mm:ss:SSS"));

        const status = await git_status(userCodeFilePath);
        console.log("status", moment().format("HH:mm:ss:SSS"));

        if (status === "unmodified") {
          const hash = await git_get_last_commit_hash();
          console.log("hash", moment().format("HH:mm:ss:SSS"));

          return postMessage(godotContainerRef, {
            action: "gitSyncPong",
            usercode: code,
            commitHash: hash,
          });
        }

        await git_add(userCodeFilePath);
        if (project.dev === "blockly") {
          await git_add("blockly.xml");
          console.log("git_add - blockly", moment().format("HH:mm:ss:SSS"));
        }
        const sha = await git_commit("auto commit by riders");
        console.log("git_commit", moment().format("HH:mm:ss:SSS"));
        postMessage(godotContainerRef, {
          action: "gitSyncPong",
          usercode: code,
          commitHash: sha,
        });
        if (project.dev === "blockly") {
          git_push(["blockly.xml", userCodeFilePath]);
        } else {
          git_push([userCodeFilePath]);
        }
        setLastCommitHash(sha);
      } catch (error) {
        console.error(error);
      }
    },

    readFileContentRequest: async function (filePath: string, _?: string) {
      try {
        const content = await readFile(filePath);
        postMessage(godotContainerRef, {
          action: "readFileContentResponse",
          filePath: filePath,
          content: content,
          success: true,
        });
      } catch (error) {
        postMessage(godotContainerRef, {
          action: "readFileContentResponse",
          filePath: filePath,
          success: false,
        });
      }
    },

    writeFileContentRequest: async function (
      filePath: string,
      content: string
    ) {
      try {
        await writeFile(filePath, content);
        const ls = await readDir("/tmp");
        postMessage(godotContainerRef, {
          action: "writeFileContentResponse",
          filePath: filePath,
          success: true,
          data: ls,
        });
      } catch (error) {
        postMessage(godotContainerRef, {
          action: "writeFileContentResponse",
          filePath: filePath,
          success: false,
        });
      }
    },

    deleteFileContentRequest: async function (filePath: string) {
      try {
        await deleteFile(filePath);
        const ls = await readDir("/tmp");
        postMessage(godotContainerRef, {
          action: "deleteFileContentResponse",
          filePath: filePath,
          success: true,
          data: ls,
        });
      } catch (error) {
        postMessage(godotContainerRef, {
          action: "deleteFileContentResponse",
          filePath: filePath,
          success: false,
        });
      }
    },

    projectInfoRequest: async function () {
      try {
        postMessage(godotContainerRef, {
          action: "projectInfoResponse",
          project: project,
        });
      } catch (error) {
        postMessage(godotContainerRef, {
          action: "projectInfoResponse",
          project: null,
        });
      }
    },

    gitTrigger: debounce(async function () {
      try {
        const info = await gitSyncHandler(["tmp"], true);
        postMessage(godotContainerRef, {
          action: "gitTriggerResponse",
          status: "success",
          commitHash: info.hash,
        });
        parentPostMessage({
          action: "gitTriggerResponse",
          status: "success",
          commitHash: info.hash,
        });
      } catch (error) {
        postMessage(godotContainerRef, {
          action: "gitTriggerResponse",
          status: "error",
          commitHash: null,
        });
        parentPostMessage({
          action: "gitTriggerResponse",
          status: "error",
          commitHash: null,
        });
      }
    }, 5000),

    getToken: async function () {
      postMessage(godotContainerRef, {
        action: "getTokenResponse",
        token: getToken(),
      });
    },

    showHTML: function (action: { action: string; content: string }) {
      console.log("showHTML in editorUI worked.");
      let newWindow: WindowProxy | null = window.open();
      newWindow?.document.write(action.content);
      newWindow?.document.close();
    },
  };

  const godot_iframe_handler = async (action: {
    action: String;
    frame: "gd" | "doc";
    data: any;
  }) => {
    if (action.action === "ready") {
      var result: any = [];

      for (let filename of action.data) {
        result.push({
          name: filename,
          content: await readFile(filename),
        });
      }

      const message = JSON.stringify({
        action: "init",
        key: getBasePath(),
        data: result,
      });

      godotContainerRef.current?.contentWindow.postMessage(message, "*");
    } else if (action.action === "save") {
      for (let item of action.data) {
        writeFile(item.name, item.content);
      }
    }
  };

  const doc_iframe_handler = async (action: {
    action: String;
    frame: "gd" | "doc";
    data: any;
  }) => {
    if (action.action === "ready") {
      var result: any = [];

      for (let filename of action.data) {
        result.push({
          name: filename,
          content: await readFile(filename),
        });
      }

      const message = JSON.stringify({
        action: "init",
        key: getBasePath(),
        data: result,
      });

      documentationRef.current?.contentWindow.postMessage(message, "*");
    } else if (action.action === "save") {
      for (let item of action.data) {
        writeFile(item.name, item.content);
      }
    }
  };

  const messageListener = useCallback(async (event: any) => {
    // if (event.origin !== "https://d32lhafmwoak77.cloudfront.net") {
    //   return;
    // }
    // Get actionType and Frame info from the message then take actions.

    const message =
      typeof event.data === "string" ? JSON.parse(event.data) : {};
    var actionType: string = message.action;

    if (
      [
        "readFileContentRequest",
        "writeFileContentRequest",
        "fsList",
        "deleteFileContentRequest",
      ].includes(actionType)
    ) {
      actionMap[actionType](message.filePath, message.content);
      return;
    }

    if (
      [
        "init",
        "postScenario",
        "postRobot",
        "gitSyncPing",
        "listFiles",
        "gitTrigger",
        "getToken",
        "projectInfoRequest",
        "showHTML",
      ].includes(message.action)
    )
      actionMap[actionType](message, project);
    else if (
      [
        "achieve",
        "score_results",
        "getachievements",
        "setProgression",
      ].includes(message.action)
    )
      actionMap[actionType](message, getToken());
    // Have we got any scenarios that we have no message.action?
    else if (message.frame) {
      if (message.frame === "gd") godot_iframe_handler(message);
      else doc_iframe_handler(message);
    }
  }, []);

  useEffect(() => {
    console.log("CommunicationContext useEffect");
    (async () => {
      window.addEventListener("message", messageListener);
      return () => {
        window.removeEventListener("message", messageListener);
      };
    })();
  }, []);

  return (
    <CommunicationContext.Provider
      value={{
        postMessage,
        parentPostMessage,
        encryptMessage,
      }}
    >
      {children}
    </CommunicationContext.Provider>
  );
};

Communication.context = CommunicationContext;
